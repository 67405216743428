<template>
        <!-- <div id="preloader">
            <div class="spinner">
                <div class="uil-ripple-css">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div> -->
        <header dir="rtl" class="site-header fixed-top">
            <div class="container-fluid">
                <div class="row justify-content-between">
                    <div class="col-xl-1 col-lg-1 my-auto d-none d-lg-block">
                        <div class="d-flex">
                            <a href="#" class="sidebar-toggle">
                                <i class="far fa-bars"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 my-auto d-none d-lg-block" style="margin-right: -15px;padding-right: 0;">
                        <div class="d-flex">
                            <div class="logo my-auto">
                                <router-link to="/"  class="custom-logo-link"><img src="../../assets/images/CR_logo.png" class="custom-logo" alt="كورساتك"></router-link> </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-7 my-auto d-none d-xl-block">
                        <form class="ajax-search-form" @submit.prevent="SearchData()">
                            <input type="text" v-model="search" class="keyword" placeholder="Search">
                            <button type="submit"><i class="fa fa-search"></i></button>
                            <ul class="datafetch"></ul>
                        </form>
                    </div>
                    <div class="col-xl-2 col-lg-2 my-auto" >
                        <div class="d-flex">
                            <div class="d-flex">
                                <div class="top-header-action">
                                    <div class="widget-header">
                                        <div class="col-12 my-auto">
                                            <div class="row">
                                                <div class="col-10" v-if="showHeader">
                                                    <label class="toggle-switch pointer"   >
                                                    <router-link to="/Unsubscribe" class="viewtube-btn  bordered" style="display: inline-block;text-align: center;font-size: 12px;" tabindex="0" savefrom_lm_index="0">الغاء الاشتراك</router-link>
                                                    </label>
                                                </div>
                                                <div class="col-6" v-else>
                                                    <label class="toggle-switch pointer" >
                                                    <a @click="gotoSpay()" class="viewtube-btn  bordered" style="display: inline-block" tabindex="0" savefrom_lm_index="0">إشتـراك</a>

                                                    </label>
                                                </div>
                                                <div class="col-2" v-if="showHeader">
                                                    <label class="toggle-switch pointer"  >
                                                    </label>
                                                </div>
                                                <div class="col-6" v-else>
                                                    <label class="toggle-switch pointer" >
                                                    <router-link to="/login" class="viewtube-btn  bordered" style="display: inline-block" tabindex="0" >دخول</router-link>

                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <!-- <div class="my-account-widget">
                                            <div class="my-account-button">
                                                <i class="fad fa-user"></i>
                                            </div>
                                            <div class="my-account-content" dir="rtl">
                                                <div class="header-profile-login">
                                                    <h6 class="text-center">تسجيل الدخول الي حسابك</h6>
                                                    <form name="loginform" id="loginform" action="#" method="post">
                                                        <p class="login-username">
                                                            <label for="user_login">رقم الهاتف</label>
                                                            <input type="text" name="log" id="user_login" class="input" size="14" placeholder="2491XXXXXXXX" />
                                                        </p>
                                                        
                                                        <p class="login-submit">
                                                            <input type="submit" style="width: 100%;" name="wp-submit" id="wp-submit" class="button button-primary" value="تسجيل دخول" />
                                                        </p>
                                                    </form>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="off-canvas-menu-bar">
            <div class="pl-30 pr-30">
                <div class="row">
                    <div class="col-5 my-auto">
                        <div class="row">
                            <div class="col-8" style="padding-right: 0;padding-left: 0;">
                                                    <label class="toggle-switch pointer" style="width: auto;"   v-if="showHeader">
                                                    <router-link to="/Unsubscribe" class="viewtube-btn  bordered" style="display: inline-block;text-align: center;font-size: 11px;" tabindex="0" savefrom_lm_index="0">الغاء الاشتراك</router-link>
                                                    </label>
                                                    <label class="toggle-switch pointer" style="width: auto;" v-else>
                                                    <a @click="gotoSpay()" style="display: flex;padding: 5px 16px;" class="viewtube-btn  bordered" tabindex="0" savefrom_lm_index="0">إشتـراك</a>

                                                    </label>
                                                </div>
                                                <div class="col-4" style="padding-right: 0;padding-left: 0;">
                                                    <label class="toggle-switch pointer"   v-if="showHeader">
                                                    </label>
                                                    <label class="toggle-switch pointer" style="width: auto;" v-else>
                                                    <router-link to="/login" class="viewtube-btn  bordered" style="display: inline-block;padding: 5px 10px;margin-left: -15px;" tabindex="0" savefrom_lm_index="0">دخول</router-link>

                                                    </label>
                                                </div>
                        </div>


                        
                    </div>
                    <div class="col-5 my-auto">
                        <router-link to="/" class="custom-logo-link" rel="home" aria-current="page"><img width="512" height="85" style="margin-left: -10px;" src="../../assets/images/CR_logo.png" class="custom-logo" alt="كورساتك"></router-link>
                    </div>
                        <div class="col-2 my-auto">
                        <div class="mobile-nav-toggler" ><span class="fas fa-bars" ></span></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 " style="margin-top: 10px;padding-right: 0;padding-left: 0;">
                        <div class="col-xl-7 col-lg-7 my-auto  d-xl-block" style="padding-right: 0;padding-left: 0;">
                        <form class="ajax-search-form" @submit.prevent="SearchData()">
                            <input type="text" v-model="search" class="keyword" placeholder="Search">
                            <button type="submit"><i class="fa fa-search"></i></button>
                            <ul class="datafetch"></ul>
                        </form>
                    </div>
                    </div>
                    
                        
                </div>
            </div>
        </div>
        <div class="off-canvas-menu">
            <div class="menu-backdrop"></div>
            <i class="close-btn fa fa-close"></i>
            <nav class="mobile-nav">
                <div class="text-center pt-3 pb-3">
                    <router-link to="/" class="custom-logo-link" rel="home"><img src="../../assets/images/CR_logo.png" class="custom-logo" alt="Sayara"></router-link>
                </div>
                <ul class="navigation">
                </ul>
            </nav>
        </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'AppHeader',
   data() {
     return {
        
     }
    },
    
    mounted() {
       let externalScript = document.createElement("script");
       externalScript.setAttribute("src", "/static/js/main.js");
       document.head.appendChild(externalScript);

       let externalScript2 = document.createElement("script");
       externalScript2.setAttribute("src", "/static/js/search.js");
       document.head.appendChild(externalScript2);
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const search = ref([]);
        const SearchData = () => {
            router.push({ name: "Searchs", params: { search_id: search.value } });
        }
        const cookie = useCookie()
        const showHeader = ref(true);
        watchEffect(() => {
            if (cookie.isCookieAvailable("status") && cookie.isCookieAvailable("msisdn")) {
                HTTP.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+ `&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status === 1 ) {
                        return;
                    } else {
                        cookie.removeCookie("remming_minutes");
                        cookie.removeCookie("msisdn");
                        cookie.removeCookie("status");
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            }
        });  
        // return {  };
        return { showHeader,search, SearchData };
    },
    methods: {
      gotoSpay() {
      window.open("http://sudanipay.com/subscription/courseatk/courseatk", "_self");
    // router.push({ name: "Contents", params: { content_id: publish.id } });
    },
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}



</style>