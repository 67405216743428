import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/seriesContent/:series_id',
    name: 'SeriesContent',
    component: () => import(/* webpackChunkName: "seriesContent" */ '../views/page/SeriesContent.vue'),
    props: true,
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: () => import(/* webpackChunkName: "Teachers" */ '../views/page/Teachers.vue')
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import(/* webpackChunkName: "Courses" */ '../views/page/Courses.vue')
  },
  {
    path: '/teachersContent/:teachers_id',
    name: 'TeachersContent',
    component: () => import(/* webpackChunkName: "TeachersContent" */ '../views/page/TeachersContent.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/user/Unsubscribe.vue')
  },
  {
    path: '/contents/:content_id',
    name: 'Contents',
    component: () => import(/* webpackChunkName: "contents" */ '../views/page/Contents.vue'),
    props: true
  },
  {
    path: '/searchs/:search_id',
    name: 'Searchs',
    component: () => import(/* webpackChunkName: "searchs" */ '../views/page/Searchs.vue'),
    props: true
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFounds',
    component: () => import(/* webpackChunkName: "notFounds" */ '../views/page/NotFounds.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
