<template>
  <div class="content">
  <img src="https://d3hz53nx0dymf8.cloudfront.net/ProductShots/20190411-1/PW_APRIL2019_IMAGE1_c.jpg" alt="">
</div>
<div id="loaderDiv" loader="" style="">
  <div id="container">
    <div class="uil-ripple-css">
      <div></div>
      <div></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
 name:'AppLoading'
}
</script>

<style scoped>

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
}

#loaderDiv {
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 1;
    right: 0;
    bottom: 0; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

#loaderContainer {
    width: 100%;
    height: 400px;
    position: relative
}

#loaderContainer #loaderDiv {
    position: absolute;
    background-color: #16212f
}

#loaderDiv {
    position: fixed;
    background-color: #fff
}

@-ms-keyframes uil-ripple {
    0%,100% {
        opacity: 0
    }

    0% {
        width: 0;
        height: 0;
        margin: 0
    }

    33% {
        width: 44%;
        height: 44%;
        margin: -22% 0 0 -22%;
        opacity: 1
    }

    100% {
        width: 88%;
        height: 88%;
        margin: -44% 0 0 -44%
    }
}

@-moz-keyframes uil-ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0
    }

    33% {
        width: 44%;
        height: 44%;
        margin: -22% 0 0 -22%;
        opacity: 1
    }

    100% {
        width: 88%;
        height: 88%;
        margin: -44% 0 0 -44%;
        opacity: 0
    }
}

@-webkit-keyframes uil-ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0
    }

    33% {
        width: 44%;
        height: 44%;
        margin: -22% 0 0 -22%;
        opacity: 1
    }

    100% {
        width: 88%;
        height: 88%;
        margin: -44% 0 0 -44%;
        opacity: 0
    }
}

@-o-keyframes uil-ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0
    }

    33% {
        width: 44%;
        height: 44%;
        margin: -22% 0 0 -22%;
        opacity: 1
    }

    100% {
        width: 88%;
        height: 88%;
        margin: -44% 0 0 -44%;
        opacity: 0
    }
}

@keyframes uil-ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0
    }

    33% {
        width: 44%;
        height: 44%;
        margin: -22% 0 0 -22%;
        opacity: 1
    }

    100% {
        width: 88%;
        height: 88%;
        margin: -44% 0 0 -44%;
        opacity: 0
    }
}

.uil-ripple-css {
    background: 0 0;
    position: relative;
    width: 400px;
    height: 400px;
    /* margin: -50px 0 0 -50px */
}

.uil-ripple-css div {
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0;
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    border-width: 20px;
    border-style: solid;
    -moz-animation: uil-ripple 2s ease-out infinite;
    -webkit-animation: uil-ripple 2s ease-out infinite;
    -o-animation: uil-ripple 2s ease-out infinite;
    animation: uil-ripple 2s ease-out infinite
}

.uil-ripple-css div:nth-of-type(1) {
    border-color: #007bff
}

.uil-ripple-css div:nth-of-type(2) {
    border-color: #007bff;
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s
}
</style>