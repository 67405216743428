<template>
    <footer class="site-footer">
            <div class="container">
                <div class="row">
                    <div class="col-2 col-sm-4 col-lg-4 col-xl-3" style="margin-left: -15px;">
                        <a>
                            <img style="max-width: 40px;" class="img-fluid" src="../../assets/images/logo.png" title="appstore" alt="appstore">
                        </a>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-4 col-xl-3" style="margin-left:-4%">
                        <a >
                            <img style="max-width: 175px;margin-top: 5px;margin-left:-4%" class="img-fluid" src="../../assets/images/CR_logo.png" title="appstore" alt="appstore">
                        </a>
                    </div>
                </div>
            </div>
            <div class="copyright-bar">
                <div class="col-sm-12 text-center">
                    <p>Copyright © 2022 Byte All Rights Reserved. </p>
                </div>
            </div>
        </footer>
<div id="backtotop" style="display: block;"><i class="fal fa-lg fa-arrow-up"></i></div>
</template>

<script>
export default {
   name: 'AppFooter'
}
</script>

<style>

</style>